import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Section from './section';
import Typography from './typography';

const FormGroupRoot = styled.div`
    display: ${props => props.inline ? 'inline-block' : 'block'};
`;

const FormInput = styled.input`
    border: none;
    background-color: ${props => props.dark ? props.theme.palette.silver.main : props.theme.palette.white.main};
    color: ${props => props.theme.palette[props.color] ? props.theme.palette[props.color].main : props.theme.typography.color.main};
    display: block;
    width: 100%;
    padding: .8rem;
    outline: none;
    min-width: none;
`;

const FormGroup = ({ inputRef, label, name, placeholder, inputAs, inline, type, required }) => {
    return (
        <FormGroupRoot inline={inline}>
            <Section bottom=".5rem">
                <Typography as="label" family="display" size="6" weight="medium" spacing=".1em">
                    {label}
                </Typography>
            </Section>
            <Typography family="default" size="5" color="black" contents>
                <FormInput
                    ref={inputRef}
                    type={type}
                    as={inputAs}
                    name={name}
                    placeholder={placeholder}
                    required={required}
                    rows="8"
                />
            </Typography>
        </FormGroupRoot>
    );
}

FormGroup.propTypes = {
    inline: PropTypes.bool,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    inputAs: PropTypes.string,
    type: PropTypes.string,
    required: PropTypes.bool,
}

FormGroup.defaultProps = {
    inline: false,
    placeholder: '',
    inputAs: 'input',
    type: 'text',
    required: false,
    value: '',
}

export { FormGroup, FormInput }

export default {
    group: FormGroup,
    input: FormInput,
};