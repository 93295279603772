import React from 'react';
import PropTypes from 'prop-types';
import Typography from 'src/components/base/typography';
import Flex from 'src/components/base/flex';
import Section from 'src/components/base/section';
import Icon from 'src/components/base/icon';
import CheckmarkIcon from 'src/images/icons/checkmark.svg';

const SuccessAlert = ({ children }) => {
    return (
        <Typography color="success">
            <Flex.box align="center">
                <Section right="1rem">
                    <Icon size="1rem">
                        <CheckmarkIcon />
                    </Icon>
                </Section>
                <Flex.item fluid>
                    {children}
                </Flex.item>
            </Flex.box>
        </Typography>
    );
}

SuccessAlert.propTypes = {
    children: PropTypes.string,
}

export default SuccessAlert;