import React from 'react';
import Section from 'src/components/base/section';
import { ContainerCenter } from 'src/components/base/container';
import Flex from 'src/components/base/flex';
import Typography from 'src/components/base/typography';
import { FormInput } from 'src/components/base/form';
import Button from 'src/components/base/button';
import SuccessAlert from 'src/components/alert/success';
import addToMailchimp from 'gatsby-plugin-mailchimp';

const NewsletterForm = (props) => {

    const emailRef = React.useRef();
    const [submitted, setSubmitted] = React.useState(false);

    const validateEmail = (email) => {
        // eslint-disable-next-line
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(String(email).toLowerCase())
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const email = emailRef.current.value;
        if (email && validateEmail(email)) {
            await addToMailchimp(email);
            setSubmitted(true);
        } else {
            alert('Please enter a valid email address');
        }
    }

    return (
        <ContainerCenter>
            <Section y="4rem">
                <Section bottom=".2rem">
                    <Typography as="h6" family="display" weight="bold" size="4" block> 
                        Never miss a blog post
                    </Typography>
                </Section>
                <p>
                    Join our email list to stay up to date on new blog posts
                </p>
                <form onSubmit={handleSubmit}>
                    <Flex.box direction="row">
                        <Flex.item fluid>
                            <FormInput
                                dark={props.dark}
                                color="primary"
                                ref={emailRef}
                                type="email"
                                required
                                placeholder="Enter your email address"
                                style={{
                                    padding: '.5rem .8rem'
                                }}
                            />
                        </Flex.item>
                        <Flex.item style={{paddingLeft: '1rem'}}>
                            <Button onClick={handleSubmit}>Submit</Button>
                        </Flex.item>
                    </Flex.box>
                </form>
                {submitted && (
                    <SuccessAlert>
                        Thank you for subscribing to our email list!
                    </SuccessAlert>
                )}
            </Section>
        </ContainerCenter>
    );
}

export default NewsletterForm;