import styled from 'styled-components';

export default styled.ul`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    list-style: none;
    margin: 0;
    padding: 0;

    li {
        flex: 1 1 auto;
        margin: .5rem 0;

        button {
            &:hover {
                color: ${props => props.theme.palette.white.alpha.heavy};
            }
        }
        &[data-active="true"] button {
            cursor: auto;
            color: ${props => props.theme.palette.primary.main};
        }
    }

    @media only screen and (min-width: ${props => props.theme.breakpoint[props.breakpoint] || props.theme.breakpoint.desktop}px) {
        flex-direction: row;
    }
`;