import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'src/components/layout/main';
import Section from 'src/components/base/section';
import HeroTitle from 'src/components/hero/title';
import Container from 'src/components/base/container';
import Typography from 'src/components/base/typography';
import { ButtonBase } from 'src/components/base/button';
import TagList from 'src/components/blog/tagList';
import BlogList from 'src/components/blog/list';
import NewsletterForm from '../components/blog/newsletterForm';

const tags = [
    { display: 'Recent', key: 'recent' },
    { display: 'Programming', key: 'programming' },
    { display: 'Modeling & Simulation', key: 'modeling_and_simulation' },
    { display: 'Data Analysis', key: 'image_analysis' },
    { display: 'Miscellaneous', key: 'all' },
];

const BlogPage = (props) => {

    const { data } = props;

    const [activeTag, setActiveTag] = React.useState(tags[0].key);

    const compareActiveTag = (tag) => activeTag === tag.key;

    const handleChangeTag = (tag) => () => {
        if (!compareActiveTag(tag)) {
            setActiveTag(tag.key);
        }
    }
    
    return (
        <Layout page="/blog">
            <Section color="dark">
                <HeroTitle>Blog</HeroTitle>

                <Section bottom="2rem">
                    <Container large="800px">
                        <Typography family="display" align="center" weight="medium" contents>
                            <TagList>
                                {tags.map((tag, i) => (
                                    <li key={i} data-active={compareActiveTag(tag)}>
                                        <ButtonBase
                                            nopadding
                                            onClick={!compareActiveTag(tag) ? handleChangeTag(tag) : null}
                                            hoverColor='transparent'
                                        >
                                            {tag.display}
                                        </ButtonBase>
                                    </li>
                                ))}
                            </TagList>
                        </Typography>
                    </Container>
                </Section>

                <Section y="4rem">
                  <BlogList blogPosts={data[activeTag].nodes} />
                </Section>

                <Section y="6rem">
                  <NewsletterForm />
                </Section>

            </Section>
        </Layout>
    );
}

export const query = graphql`
  query {
    recent: allContentfulBlog(
      limit: 20
      sort: { fields: createdAt, order: DESC }
    ) {
      nodes {
          title
          id
          createdAt
          shortDescription
          slug
          featuredImage {
            file {
              url
              fileName
            }
          }
        }
    }
    programming: allContentfulBlog(
      limit: 10
      sort: { fields: createdAt, order: DESC }
      filter: { category: { elemMatch: { slug: { eq: "programming" } } } }
    ) {
      nodes {
          title
          id
          createdAt
          shortDescription
          slug
          featuredImage {
            file {
              url
              fileName
            }
          }
        }
    }
    all: allContentfulBlog(
      limit: 10
      sort: { fields: createdAt, order: DESC }
      filter: { category: { elemMatch: { slug: { eq: "data-analysis" } } } }
    ) {
      nodes {
          title
          id
          createdAt
          shortDescription
          slug
          featuredImage {
            file {
              url
              fileName
            }
          }
        }
    }
    modeling_and_simulation: allContentfulBlog(
      limit: 10
      sort: { fields: createdAt, order: DESC }
      filter: { category: { elemMatch: { slug: { eq: "modeling-and-simulation" } } } }
    ) {
      nodes {
          title
          id
          createdAt
          shortDescription
          slug
          featuredImage {
            file {
              url
              fileName
            }
          }
        }
    }
    image_analysis: allContentfulBlog(
      limit: 10
      sort: { fields: createdAt, order: DESC }
      filter: { category: { elemMatch: { slug: { eq: "image-analysis" } } } }
    ) {
      nodes {
          title
          id
          createdAt
          shortDescription
          slug
          featuredImage {
            file {
              url
              fileName
            }
          }
        }
    }
  }
`

export default BlogPage;